/* eslint-disable no-unused-expressions */
import { StackActions, CommonActions } from '@react-navigation/native';
import { createRef } from 'react';

export const navigationRef = createRef();


const navigateWithReplace = (routeName, params = {}) => {
  navigationRef.current?.dispatch(
    StackActions.replace(routeName, params),
  );
};

const navigate = (routeName, params = {}) => {
  navigationRef.current?.navigate(routeName, params);
};
const goBack = (arg) => {
  navigationRef.current?.dispatch(CommonActions.goBack(arg));
};

const reset = (routeName) => {
  navigationRef.current?.dispatch(CommonActions.reset({ routeName }));
};

const getCurrentRoute = () => {
  return navigationRef.current?.getCurrentRoute();
}

const navigationService = {
  navigate,
  navigateWithReplace,
  goBack,
  reset,
  getCurrentRoute
};

export default navigationService;
