import React from 'react';
import Common from 'src/components/Common';
import { styles } from './style';
import { getAvatar } from '../Message/style';
import useProfile from 'src/hooks/useProfile';
import { getDefaultMessageAttributes } from 'src/screens/Chat/utils';
import { captureException } from '@sentry/react-native';
import useApprovalActions from 'src/hooks/useApprovalActions';

const messages = {
  APPROVE: 'Yes',
  DECLINE: 'No',
};

export default function ApprovalTile({ approvalName, channel, onClose }) {
  const { companyInfo } = useProfile();
  const handleApprove = async () => {
    try {
      channel.sendMessage(messages.APPROVE, getDefaultMessageAttributes());
      onClose();
    } catch (error) {
      captureException(new Error(`Failed to approve: ${error.message}`));
    }
  };
  const handleDecline = async () => {
    try {
      channel.sendMessage(messages.DECLINE, getDefaultMessageAttributes());
      onClose();
    } catch (error) {
      captureException(new Error(`Failed to decline: ${error.message}`));
    }
  };
  const formattedApprovalName = `Need ${approvalName ? approvalName.replace(':', '').toLowerCase(): 'approval for an action'}`;

  useApprovalActions({ twilioChannel: channel, onApprove: handleApprove, onDecline: handleDecline });

  return (
    <Common.View style={styles.container}>
      <Common.View transparent style={styles.header}>
        <Common.Avatar style={styles.avatar} source={getAvatar(companyInfo)} />
        <Common.Text style={styles.desc}>“{formattedApprovalName}”</Common.Text>
        <Common.Text style={styles.title}>Do you approve this request?</Common.Text>
      </Common.View>
      <Common.View transparent style={styles.actions}>
        <Common.TouchableOpacity testID='approve' style={styles.action} onPress={handleApprove}>
          <Common.Text style={styles.buttonText}>Approve</Common.Text>
        </Common.TouchableOpacity>
        <Common.TouchableOpacity testID='decline' style={[styles.action, styles.actionOutlined]} onPress={handleDecline}>
          <Common.Text style={styles.outlinedButtonText}>Decline</Common.Text>
        </Common.TouchableOpacity>
      </Common.View>
    </Common.View>
  );
}
