import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    zIndex: 2,
    flex: 1,
    marginVertical: 10,
    marginHorizontal: 10,
    top: 0,
    left: '50%',
    paddingHorizontal: 12,
    paddingVertical: 10,
    borderRadius: 4,
    backgroundColor: '#DBEEFC',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default styles;
