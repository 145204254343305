import { useEffect, useState } from 'react';
import routeNames from 'src/constants/routeNames';
import navigationService from 'src/utils/navigation';
import { normalizeTicket } from 'src/utils/normalizer';
import { useDispatchMapper } from './actionHooks';
import { setActiveTicketAction } from 'src/redux/chat/chatActions';

const useApprovalActions = ({ approvalThreads, twilioChannel, onApprove, onDecline }) => {
  const [isWarningVisible, setWarningVisible] = useState(false);
  const saveActiveTicket = useDispatchMapper(setActiveTicketAction);
  const getApprovalState = () => {
    return JSON.parse(localStorage.getItem('approvalState')) || {};
  };

  // Redirecting to chat screen if approvalThreadId is found
  useEffect(() => {
    const { approvalThreadId } = getApprovalState();
    if (!approvalThreadId || !Array.isArray(approvalThreads)) {
      return;
    }

    const thread = approvalThreads.find((el) => el.id === approvalThreadId);
    if (!thread) {
      setWarningVisible(true);
      localStorage.removeItem('approvalState');
      return;
    }

    if (navigationService.getCurrentRoute()?.name !== routeNames.CHAT) {
      const ticketData = normalizeTicket(thread);
      saveActiveTicket(ticketData);
      navigationService.navigate(routeNames.CHAT, { ticket: ticketData });
    }
  }, [approvalThreads]);

  // Handling approval actions
  useEffect(() => {
    const { approvalAction } = getApprovalState();
    if (!approvalAction || !twilioChannel) {
      return;
    }

    if (approvalAction === 'approve') {
      onApprove();
    } else if (approvalAction === 'deny') {
      onDecline();
    }
    localStorage.removeItem('approvalState');
  }, [twilioChannel]);

  return { isApprovalWarningVisible: isWarningVisible };
};

export default useApprovalActions;
