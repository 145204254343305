import React, { useEffect, useRef } from 'react';
import Common from 'src/components/Common';
import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';
import { Animated } from 'react-native';
import styles from './style';

export default function ApprovalWarning() {
  const translateY = useRef(new Animated.Value(-100)).current;

  useEffect(() => {
    Animated.sequence([
      Animated.timing(translateY, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }),
      Animated.delay(3000),
      Animated.timing(translateY, {
        toValue: -100,
        duration: 500,
        useNativeDriver: true,
      }),
    ]).start();
  }, []);

  return (
    <Animated.View
      style={[
        styles.container,
        {
          transform: [{ translateX: '-50%' }, { translateY }],
          opacity: translateY.interpolate({
            inputRange: [-100, 0],
            outputRange: [0, 1],
          }),
        },
      ]}
    >
      <InfoIcon style={{ minWidth: 20, marginRight: 10 }} />
      <Common.Text style={{ fontSize: 14 }}>Looks like the approval for this request has already been handled.</Common.Text>
    </Animated.View>
  );
}
