import axios from 'axios';
import Authentication from 'src/utils/auth';
import { store } from 'src/redux/store';
import * as Sentry from '@sentry/react-native';
import Config from 'react-native-config';
import { updateProfileAction } from 'src/redux/profile/profileActions';
import { auth0Config } from 'src/config';
import { Platform } from 'react-native';
import parse from 'url-parse';
import jwtDecode from 'jwt-decode';
import { chatTypes } from 'src/constants';

const { CHATGENIE_API_URL, CHATGENIE_CONTACT_API_URL } = Config;

const getNewAccessToken = (refreshToken) =>
  axios({
    method: 'POST',
    url: `https://${auth0Config.domain}/oauth/token`,
    headers: { 'content-type': 'application/json' },
    data: {
      grant_type: 'refresh_token',
      client_id: auth0Config.clientId,
      refresh_token: refreshToken,
      scope: auth0Config.scope,
    },
  });

export const refreshAccessToken = async () => {
  const { token: accessToken, refreshToken } = store?.getState()?.profile || {};
  let token = accessToken;
  try {
    if (refreshToken) {
      const response = await getNewAccessToken(refreshToken);
      token = response.data?.access_token;
      store.dispatch(updateProfileAction({ token }));
    }
  } catch (err) {
    Authentication.logout();
  }
  return token;
};

const errorsWhitelist = {
  '/oauth/passwordless/token': [403],
  '/companies/messenger/installations': [403],
  default: [404, undefined, 0],
};

const shouldLogout = (response) => {
  const configURL = response?.config?.url;
  if (errorsWhitelist.hasOwnProperty(configURL)) {
    return false;
  }
  return response?.status === 401 || response?.status === 403;
};

const api = axios.create({
  baseURL: CHATGENIE_API_URL,
});
const contactApi = axios.create({
  baseURL: CHATGENIE_CONTACT_API_URL,
});

// refresh token when it is going to expire within 24 hours
const TOKEN_REFETCH_PERIOD = 24 * 60 * 60 * 1000;

const requestSuccessInterceptor = async (config) => {
  if (!config.headers.Authorization) {
    let token = store?.getState()?.profile?.token;
    if (token) {
      const jwt = jwtDecode(token);

      const tokenValidTime = new Date(jwt.exp * 1000).getTime();
      const isAboutToExpire = tokenValidTime - TOKEN_REFETCH_PERIOD < new Date().getTime();
      if (isAboutToExpire) {
        token = await refreshAccessToken();
      }
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  const appId = store?.getState()?.profile?.companyInfo?.appId;
  config.headers['APP-ID'] = config.headers['APP-ID'] || appId;
  return config;
};

const responseErrorInterceptor = async (error) => {
  const response = error?.response;
  if (shouldLogout(response)) {
    const originalRequest = error.config;
    if (!originalRequest._retry) {
      originalRequest._retry = true;
      const token = await refreshAccessToken();
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      return api(originalRequest);
    }
    Authentication.logout();
    return error;
  }
  Sentry.configureScope((scope) =>
    scope.setContext('API response', {
      url: `${response?.config?.method?.toUpperCase()}: ${response?.config?.baseURL}${response?.config?.url}`,
      token: response?.config?.headers?.Authorization,
      status: response?.status,
      data: response?.data,
      request_payload: response?.config?.data,
    }),
  );
  const isWhiteListed =
    errorsWhitelist?.[response?.config?.url]?.includes(response?.status) ||
    errorsWhitelist?.default?.includes(response?.status);
  if (!isWhiteListed) {
    // sent api errors to Sentry
    Sentry.captureException(error);
  }

  return Promise.reject(error);
};
api.interceptors.request.use(requestSuccessInterceptor, (error) => Promise.reject(error));
contactApi.interceptors.request.use(requestSuccessInterceptor, (error) => Promise.reject(error));

api.interceptors.response.use((response) => response, responseErrorInterceptor);
contactApi.interceptors.response.use((response) => response, responseErrorInterceptor);

export const chatgenieAPI = {
  findUser: (email) => api.get(`/users/${email}/by-email`),
  searchUser: (email) => contactApi.post(`/v1/users/search`, { email }),
  devices: {
    fetchByContact: (nextPage, userId) => api.get(`/users/${userId}/devices?page=${nextPage}`),
    fetchByCompany: (nextPage, companyId) => api.get(`/companies/${companyId}/devices?page=${nextPage}`),
    attach: (ticketId, device) => api.post(`/tickets/${ticketId}/devices`, device),
  },
  tickets: {},
  getCompanyBasicInfo: (appId) => api.get(`/workspaces/${appId}/design`),
  getCompanyContacts: (companyId) => api.get(`/companies/${companyId}/users`),
  createTicket: (payload) => api.post('/action-platform/twilio/ticket', payload),
  sendCode: (payload) => api.post('/oauth/passwordless/start', payload),
  verifyCode: (payload) => api.post('/oauth/passwordless/token', payload),
  getCompanyInfo: (companyId) => api.get(`/companies/${companyId}`),
  getTwilioToken: (email, push_channel = Platform.OS === 'ios' ? 'apn' : 'fcm') =>
    api.post('/action-platform/twilio/token', {
      identity: email,
      push_channel,
    }),
  getUserInfo: () => api.get('/users/info').then(({ data }) => data),
  getCompanies: (queries, { headers } = {}) =>
    api
      .get(`/companies${parse.qs.stringify({ ...queries, page: queries?.page || 1 }, { addQueryPrefix: true })}`, {
        headers,
      })
      .then(({ data }) => data),
  getChannels: (queries) => api.get(`/channel/channels${parse.qs.stringify(queries, { addQueryPrefix: true })}`),
  createCompany: (name) => api.post('/companies', { name }),
  slackAuth: (code, redirectUri) => api.post('/messenger/slack/auth', { code, redirectUri }),
  teamsAuth: (code, verificationHash, redirectUri, msTeamsAppId, isRefreshToken) => {
    return api.post(`/messenger/ms-team/auth`, {
      verificationHash,
      redirectUri,
      code,
      isRefreshToken: parseInt(isRefreshToken),
      msTeamsAppId: parseInt(msTeamsAppId),
    })
  },
  windowsAuth: (code, verificationHash, redirectUri) =>
    api.post(`/messenger/windows/auth`, {
      verificationHash,
      redirectUri,
      code,
    }),
  cloudRadialAuth: (cloudRadialGuestId) =>
    api.post('/cloud-radial/auth', {
      api_key: cloudRadialGuestId,
    }),
  messengerCustomAuth: (email, hash) =>
    api.post('/messenger/auth', {
      connection_email: email,
      connection_hash: hash,
    }),
  getTeamsAuthUrl: (redirectUri, appId, chatType = chatTypes.MS_TEAMS, isRefreshToken = 0) =>
    api.get(`/messenger/ms-team/sign-in?redirectUri=${redirectUri}&appId=${appId}&chatType=${chatType}&isRefreshToken=${isRefreshToken}`),
  joinTwilioConversation: ({ data, id }) => contactApi.patch(`v1/conversations/${id}`, data),
  setMessengerInstallation: (data) => api.post(`/companies/messenger/installations`, data),
  uploadAttachmentToS3: (data) => api.post(`/attachments`, data),
  saveProfilePicture: (contactId, data) => api.post(`/contacts/${contactId}/profile-picture`, data),
  removeProfilePicture: (contactId) => api.delete(`/contacts/${contactId}/profile-picture`)
};
